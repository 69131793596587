<template>
  <!--  商品管理-->
  <div style="padding: 20px">
    <div style="margin-bottom: 10px" v-loading="load">
      <el-button @click="addStore">添加商品</el-button>
      <el-table :data="TableData" style="width: 100%" :row-style="{height: '60px'}">
        <el-table-column label="商品ID" prop="id"/>
        <el-table-column label="类型" prop="type"/>
        <el-table-column label="商品名" prop="name"/>
        <el-table-column label="商品信息" prop="about"/>
        <el-table-column prop="num">
          <template slot-scope="scope">
            {{ scope.row.num }}{{ scope.row.type === "按时" ?  "天" : "个"  }}
          </template>
        </el-table-column>
        <el-table-column label="商品价格" prop="price">
          <template slot-scope="scope">
            <div>
              {{scope.row.price * 0.01 + "元"}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品状态" prop="statue"/>
        <el-table-column label="商品管理">
          <template #default="scope">
            <el-button type="primary" plain @click="editStore(scope)">修改</el-button>
            <template>
              <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="确定删除此商品吗？"
                  @confirm="deleteStoreSure"
              >
                <el-button slot="reference" @click="deleteStore(scope)" type="danger" style="margin-left: 10px">删除
                </el-button>
              </el-popconfirm>
            </template>

          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--添加商品-->
    <el-dialog
        title="添加商品"
        :visible.sync="dialogVisible"
        width="35%">
      <el-form style="margin-left: 50px; margin-right: 70px" label-width="100px" :model="AddStoreForm">
        <el-form-item label="商品类型">
          <el-radio-group v-model="AddStoreForm.type" @change="choose1">
            <el-radio label="0">按量</el-radio>
            <el-radio label="1">按时</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="AddStoreForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="storeType">
          <el-input v-model.number="AddStoreForm.num"></el-input>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input type="textarea" v-model="AddStoreForm.about"></el-input>
        </el-form-item>
        <el-form-item label="商品状态">
          <el-radio-group v-model="AddStoreForm.statue">
            <el-radio label="0">未上架</el-radio>
            <el-radio label="1">已上架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
            label="商品价格(分)"
            prop="price"
            :rules="[
              { type: 'number', message: '价格必须是数字'}
            ]"
        >
          <el-input v-model.number="AddStoreForm.price"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addStoreSure">确 定</el-button>
      </span>
    </el-dialog>

    <!--修改商品-->
    <el-dialog
        title="修改商品"
        :visible.sync="dialogVisible1"
        width="35%">
      <el-form style="margin-left: 50px; margin-right: 70px" label-width="100px" :model="EditStoreForm">
        <el-form-item label="商品类型">
          <el-radio-group v-model="EditStoreForm.type" @change="choose2">
            <el-radio  label="0">按量</el-radio>
            <el-radio  label="1">按时</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="EditStoreForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="storeType">
          <el-input v-model.number="EditStoreForm.num"></el-input>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input type="textarea" v-model="EditStoreForm.about"></el-input>
        </el-form-item>
        <el-form-item label="商品状态">
          <el-radio-group v-model="EditStoreForm.statue">
            <el-radio label="0">未上架</el-radio>
            <el-radio label="1">已上架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
            label="商品价格(分)"
            prop="price"
            :rules="[
              { type: 'number', message: '价格必须是数字'}
            ]"
        >
          <el-input v-model.number="EditStoreForm.price"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="editStoreSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {GetAllStore, AddStoreAndUpdate, DeleteStore} from '@/request/api.js'

export default {
  inject: ['reload'],
  name: "SetCombo",
  data() {
    return {
      load: true,

      TableData: [],

      dialogVisible: false,
      dialogVisible1: false,

      AddStoreForm: {
        id: 0,
      },

      EditStoreForm: {
        name: '',
        num: 0,
        about: '',
        price: 0,
      },

      rowID: 0,

      storeType: '商品个数',
    }
  },
  methods: {
    choose1(){
      if(this.AddStoreForm.type === "0"){
        this.storeType = '商品个数';
      }else if(this.AddStoreForm.type === "1"){
        this.storeType = '商品天数';
      }
    },
    choose2(){
      if(this.EditStoreForm.type === "0"){
        this.storeType = '商品个数';
      }else if(this.EditStoreForm.type === "1"){
        this.storeType = '商品天数';
      }
    },
    //删除商品
    deleteStore(scope) {
      this.rowID = scope.row.id;
    },
    //确认删除
    deleteStoreSure() {
      console.log(this.rowID);
      DeleteStore({id: this.rowID}).then(res => {
        if (res.Code === 2000) {
          this.SuccessMessage();
        } else {
          this.ErrorMessage();
        }
        this.reload();
      })
    },
    //修改商品
    editStore(scope) {
      // console.log(scope.row);
      this.dialogVisible1 = true;
      this.EditStoreForm.id = scope.row.id;
      this.EditStoreForm.name = this.TableData[scope.$index].name;
      this.EditStoreForm.num = this.TableData[scope.$index].num;
      this.EditStoreForm.about = this.TableData[scope.$index].about;
      this.EditStoreForm.price = this.TableData[scope.$index].price;
    },
    //修改商品确认
    editStoreSure() {
      AddStoreAndUpdate(this.EditStoreForm).then(res => {
        if(res.Code === 2000){
          this.SuccessMessage();
        }else{
          this.ErrorMessage();
        }
        this.dialogVisible1 = false;
        this.reload();
      })
    },
    //添加商品显示
    addStore() {
      this.dialogVisible = true;
    },
    //确认添加
    addStoreSure() {
      AddStoreAndUpdate(this.AddStoreForm).then(res => {
        if (res.Code === 2000) {
          this.SuccessMessage();
        } else {
          this.ErrorMessage();
        }
        this.reload();
      })
      this.dialogVisible = false;
    },
    SuccessMessage() {
      this.$message({
        message: '成功',
        type: 'success'
      });
    },
    ErrorMessage() {
      this.$message({
        message: '失败',
        type: 'error'
      });
    },
    //修改显示内容
    updateTable(){
      let dataT = this.TableData;
      dataT.forEach(function (item,index){
        if(item.statue === 1){
          dataT[index].statue = "已上架";
        }else{
          dataT[index].statue = "未上架";
        }
        if(item.type === 0){
          dataT[index].type = "按量";
        }else{
          dataT[index].type = "按时";
        }
      })
    }
  },
  created() {
    GetAllStore().then(res => {
      this.TableData = res.Data;
      this.load = false;
      this.updateTable();
    })
  }
}
</script>

<style scoped>

</style>